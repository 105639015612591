import React, { Fragment } from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import TagLink from "../../components/TagLink";
import SituationsBlock from "../../components/SituationsBlock";

import { Heading } from "../../style/Heading";
import { Container, Flex, Wrapper } from "../../style/Grid";
import { Text } from "../../style/Text";
import situationOrderConfig from "../../config/situationOrderConfig";

const SituationsTemplate = ({
  data: {
    wp: {
      categories: { nodes: categories },
      products: { nodes: products },
      page: {
        title,
        uri,
        seo: { metaDesc },
      },
    },
  },
}) => {
  const highlightedProducts = products.filter(
    product => product.productMedailon.highlightedproduct
  );

  const situations = situationOrderConfig(
    categories
      .filter(nodes => nodes.pages.nodes.length > 0)
      .map(
        ({
          pages: {
            nodes: [{ id, title, featuredImage, uri, pageContent }],
          },
        }) => {
          return {
            id,
            title,
            featuredImage,
            uri,
            pageContent,
          };
        }
      )
  );

  const breadcrumbs = [
    {
      label: title,
      link: uri,
    },
  ];

  return (
    <Layout>
      <SEO title={title} description={metaDesc} />
      <Header variant="light" />

      <Wrapper>
        <Container flexDirection="column">
          <Flex flexDirection="column" mb={[3, null, 50]}>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Heading as={"h1"} variant={"h1"} uppercase>
              {title}
            </Heading>
            <Text fontSize={18} lineHeight={"24px"} mt={24} maxWidth={589}>
              Je dobré si uvědomit, že každé rozhodnutí má konkrétní dopad na
              vaše finance. Rádi vám poradíme v mnoha životních situacích, které
              mají zásadní dopad na váš rozpočet, a pomůžeme vám udělat dobré
              rozhodnutí.
            </Text>
          </Flex>

          {situations && <SituationsBlock situations={situations} />}

          {/* Temporarily commented part */}
          {highlightedProducts && highlightedProducts.length !== 0 && false && (
            <>
              <Heading
                as="h2"
                fontSize={[3, null, 5]}
                lineHeight={"38px"}
                mb={3}
                uppercase
              >
                Vybrané produkty
              </Heading>
              <Flex
                alignItems="center"
                flexWrap="wrap"
                marginTop="auto"
                mb={[3, null, 52]}
              >
                {highlightedProducts.map((product, key) => (
                  <Fragment key={key}>
                    {product.pageContent && product.pageContent.template ? (
                      <TagLink
                        key={product.id}
                        href={`/produkt/${product.slug}/`}
                        content={product.title}
                        variant={"light"}
                        icon={"dolar-icon"}
                      />
                    ) : (
                      <TagLink
                        key={product.id}
                        content={product.title}
                        variant={"blank"}
                        icon={"dolar-icon"}
                      />
                    )}
                  </Fragment>
                ))}
              </Flex>
            </>
          )}
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default SituationsTemplate;

export const pageQuery = graphql`
  query SITUATIONS {
    wp {
      page(id: "s-cim-pomuzeme", idType: URI) {
        title
        uri
        seo {
          metaDesc
        }
      }
      categories(first: 9999, after: null) {
        nodes {
          pages {
            nodes {
              id
              title
              pageContent {
                banner {
                  title
                }
              }
              featuredImage {
                node {
                  sourceUrl(size: MEDIUM_LARGE)
                }
              }
              uri
            }
          }
        }
      }
      products(first: 9999, after: null) {
        nodes {
          title
          slug
          id
          uri
          productMedailon {
            highlightedproduct
          }
          pageContent {
            template
          }
        }
      }
    }
  }
`;

import styled from "styled-components";
import { Flex } from "../Grid";

const SituationsBlockItem = styled(Flex)``;

SituationsBlockItem.displayName = "SituationsBlockItem";

SituationsBlockItem.defaultProps = {
  flexDirection: "column",
  mb: 3,
  width: [
    1,
    "calc(50% - 8px)",
    "calc(50% - 10px)",
    "calc(33% - 10px)",
    "calc(33% - 10px)",
  ],
  border: "1px solid #CBD0D2",
  borderRadius: "4px",
};

export { SituationsBlockItem };

import styled from "styled-components";
import { Flex } from "../Grid";

const SituationsBlockWrapper = styled(Flex)``;

SituationsBlockWrapper.displayName = "SituationsBlockWrapper";

SituationsBlockWrapper.defaultProps = {
  justifyContent: "space-between",
  flexWrap: "wrap",
  mb: 52,
};

export { SituationsBlockWrapper };

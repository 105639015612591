import styled from "styled-components";
import { Image } from "../Image";

const SituationsBlockItemImage = styled(Image)``;

SituationsBlockItemImage.displayName = "SituationsBlockItemImage";

SituationsBlockItemImage.defaultProps = {
  borderRadius: 1,
  border: 2,
  width: "100%",
  height: 144,
  opacity: "0",
};

export { SituationsBlockItemImage };

import React from "react";
import { Flex } from "../../style/Grid";
import Link from "../Link";
import { MagazineBlockImage } from "../../style/MagazineBlock/MagazineBlockImage";
import { Link as FakeLink } from "../../style/Link";
import Icon from "../../style/Icon";
import { SituationsBlockItem, SituationsBlockItemHeading, SituationsBlockItemImage, SituationsBlockWrapper } from "../../style/SituationsBlock";

const SituationsBlock = ({ situations }) => (
  <SituationsBlockWrapper as={"ul"}>
    {situations.map((situation) => (
      <SituationsBlockItem key={situation.uri} as={"li"}>
        <Link href={`${situation.uri}`} variant={"shadow"}>
          <MagazineBlockImage
            width={"100%"}
            height={"144px"}
            image={situation?.featuredImage?.node?.sourceUrl ?? require("../../assets/images/placeholder/test-image-9.jpg").default}
          >
            <SituationsBlockItemImage src={situation?.featuredImage?.node?.sourceUrl ?? require("../../assets/images/placeholder/test-image-9.jpg").default} />
          </MagazineBlockImage>
          <Flex flexDirection={"column"} pt={3} pb={20} px={20}>
            <SituationsBlockItemHeading as={"h3"}>{situation.pageContent.banner.title && situation.pageContent.banner.title}</SituationsBlockItemHeading>
            <FakeLink variant="smallBorderTop" mt={2}>
              Zjistit více
              <Icon mb={"-1px"} ml={1} icon="icon-next" size={10} color="cta" />
            </FakeLink>
          </Flex>
        </Link>
      </SituationsBlockItem>
    ))}
  </SituationsBlockWrapper>
);

export default SituationsBlock;

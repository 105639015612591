import styled from "styled-components";
import { Heading } from "../Heading";

const SituationsBlockItemHeading = styled(Heading)`
  min-height: 2.5em;
`;

SituationsBlockItemHeading.displayName = "SituationsBlockItemHeading";

SituationsBlockItemHeading.defaultProps = {
  fontSize: 28,
  lineHeight: "30px",
  fontWeight: "300",
};

export { SituationsBlockItemHeading };

const orderConfig = [
  // "cGFnZTo0MTU=": [
  // ],
  // "cGFnZTo0NjI=": [
  // ],
  // "cGFnZTo0ODI=": [
  // ],
  // "cGFnZTo0ODM=": [
  // ],
  // "cGFnZTo0ODA=": [
  // ],
  // "cGFnZTo0MjQ=": [
  // ],
  // "cGFnZTo0ODE=": [
  // ],
  {
    // Rodina a budoucnost
    id: "cGFnZTo0ODM=",
    order: 1,
  },
  {
    // Bydlení
    id: "cGFnZTo0MTU=",
    order: 2,
  },
  {
    // Financování vozidel
    id: "cGFnZTo0MjQ=",
    order: 3,
  },
  {
    // Investice
    id: "cGFnZTo0NjI=",
    order: 4,
  },
  {
    // Penze
    id: "cGFnZTo0ODA=",
    order: 5,
  },
  {
    // Ochrana majetku
    id: "cGFnZTo0ODI=",
    order: 6,
  },
  {
    // Podnikání
    id: "cGFnZTo0ODE=",
    order: 7,
  },
];

export default situations => {
  situations = situations.map(situation => {
    const data = orderConfig?.find(
      tempSituation => tempSituation.id === situation.id
    );
    return {
      order: data?.order,
      ...situation,
    };
  });

  return situations.sort((a, b) => a.order - b.order);
};
